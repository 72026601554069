<template>
    <div class="my-10 flex flex-col items-center bg-gray-100 pt-6 sm:justify-center">
        <div>
            <slot name="logo" />
        </div>

        <div class="mt-6 w-full overflow-hidden bg-white px-6 py-4 shadow-md sm:max-w-md sm:rounded-lg">
            <slot />
        </div>
    </div>
</template>
